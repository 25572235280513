








import Vue from "vue";
import Vote from "../components/Vote.vue";
export default Vue.extend({
  components: {
    Vote,
  },
  data() {
    return {
      interval: null as any,
    };
  },
  computed: {
    votes() {
      return this.$store.state.votes;
    },
  },
  mounted() {
    this.$store.dispatch("getVotes").catch((e) => {
      console.error(e);
      this.$router.push("/");
    });
    this.interval = setInterval(() => {
      this.$store.dispatch("getVotes");
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
});
