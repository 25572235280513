























































































































import Vue from "vue";
import * as dayjs from "dayjs";
export default Vue.extend({
  data() {
    return {
      dayjs: dayjs,
      modal: null as any,
      decision: "agree",
    };
  },
  props: ["vote"],
  computed: {
    isAdmin(): boolean {
      return (
        process.env.VUE_APP_SLUG === 'neuruppin' && this.user.email === "v.busch@me.com" ||  process.env.VUE_APP_SLUG === 'wittstock' && this.user.email === "felixklemm@gmx.de"
      );
    },
    isCheckedIn(): boolean {
      return (
        this.checkedInUsers.findIndex((item) => item.id === this.user.id) >= 0
      );
    },
    user(): any {
      return this.$store.state.user;
    },
    event(): any {
      return this.$store.getters.activeEvent;
    },
    checkedInUsers(): any {
      return this.event.users.filter((user) => user.checked_in_at !== null);
    },
    users(): any[] {
      if (this.vote.is_anonymous) {
        return [];
      } else {
        return this.vote.users.filter(
          (item) => item.decision === this.decision
        );
      }
    },
    userVote(): any {
      return this.vote.users.find((item) => item.id === this.user.id);
    },
    agree(): any[] {
      return this.vote.users.filter((item) => item.decision === "agree");
    },
    agreed(): boolean {
      if (this.userVote) {
        return this.userVote.decision === "agree";
      }
      return false;
    },
    disagree(): any[] {
      return this.vote.users.filter((item) => item.decision === "disagree");
    },
    disagreed(): boolean {
      if (this.userVote) {
        return this.userVote.decision === "disagree";
      }
      return false;
    },
    abstain(): any[] {
      return this.vote.users.filter((item) => item.decision === "abstain");
    },
    abstained(): boolean {
      if (this.userVote) {
        return this.userVote.decision === "abstain";
      }
      return false;
    },
    totalVotes(): number {
      return this.vote.users.length;
    },
    percentage(): number {
      return this.checkedInUsers.length > 0
        ? (this.totalVotes / this.checkedInUsers.length) * 100
        : 0;
    },
  },
  methods: {
    privacy(e): void {
      this.action("anonymous_" + !e.target.checked);
    },
    action(type: any): any {
      if (this.isCheckedIn) {
        this.$store.dispatch("voteAction", { voteId: this.vote.id, type });
      }
    },
  },
});
